import useResources from './resources';

const INITIAL_FETCH_OPTIONS = {
    filter: {},
    page: { number: 0, size: 20 },
    sort: { field: 'attributes.last_name', direction: 'asc' },
};

export function useHubSpotContacts(props) {
    return useResources({
        link: 'contact.ContactCollection',
        prefix: 'contact.',
        initialFetchOptions: INITIAL_FETCH_OPTIONS,
        ...props,
    });
}
