import { createSelector } from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import { ducks } from '@arborian/narrf';

export const selectUserOrganization = createSelector(
    ducks.auth.selectUserinfo,
    ducks.jsonapi.selectObject('Organization'),
    (userinfo, orgs) => {
        const orgUuid = fp.get('orgUuid', userinfo);
        return fp.find(org => fp.get('attributes.pcc.orgUuid', org) === orgUuid, orgs);
    },
);
