import { useState } from 'react';

import { Button, Chip, Tab, Tabs, Tooltip } from '@material-ui/core';
import FacilityDataTable from 'og/components/datatables/FacilityDataTable';
import fp from 'lodash/fp';
import { useHubspotAccountLink } from 'og/api/useHubspotAccountLink';
import { useHubspotAccountUnlink } from 'og/api/useHubspotAccountUnlink';

export function OrganizationDetailView({ organization }) {
    const account = fp.get('attributes.hubspot_portal_id', organization);

    const linkHubspot = useHubspotAccountLink(organization);
    const unlinkHubspot = useHubspotAccountUnlink(organization);

    if (account) {
        return (
            <>
                <span>
                    <b>Linked Hubspot Account Id: </b>
                </span>
                <Tooltip title="Click to unlink HubSpot account">
                    <Chip label={account} onDelete={ev => unlinkHubspot(ev, organization)} />
                </Tooltip>
                <Facilities organization={organization} />
            </>
        );
    } else {
        return (
            <>
                <Button variant="contained" color="primary" onClick={ev => linkHubspot(ev, organization)}>
                    Link a HubSpot Account
                </Button>
                <Facilities organization={organization} />
            </>
        );
    }
}

function Facilities({ organization }) {
    const [tab, setTab] = useState(0);
    return (
        <>
            <Tabs value={tab} onChange={(ev, index) => setTab(index)}>
                <Tab label="Facilities" />
            </Tabs>
            <div>
                <FacilityDataTable organization={organization} />
            </div>
        </>
    );
}
