import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import fp from 'lodash/fp';

import { useApi, ducks } from '@arborian/narrf';

import { useDataTableFilter } from './datatable-filter';

export function useRoleDataTableFilter() {
    return useDataTableFilter({
        link: 'role.RoleCollection',
        path: 'Role',
        map: role => fp.get('attributes.description', role),
    });
}

export function useAllRoles() {
    const api = useApi();
    useEffect(() => {
        const href = api.url_for('role.RoleCollection');
        api.fetchAllJsonApi(href);
    }, [api]);
    return useSelector(ducks.jsonapi.selectObject(['Role']));
}
