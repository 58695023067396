import useResources from './resources';
import { useEffect } from 'react';
import { ducks, useApi } from '@arborian/narrf';
import { useSelector } from 'react-redux';
import { useDataTableFilter } from './datatable-filter';
import fp from 'lodash/fp';

const INITIAL_FETCH_OPTIONS = {
    filter: {},
    page: { number: 0, size: 20 },
    sort: { field: 'attributes.created', direction: 'desc' },
};

export function useOrganization(organizationId) {
    const api = useApi();

    useEffect(() => {
        api.fetchJson(api.url_for('organization.Organization', { org_id: organizationId }));
    }, [api, organizationId]);

    return useSelector(ducks.jsonapi.selectObject(['Organization', organizationId]));
}

export function useOrganizations(props) {
    return useResources({
        link: 'organization.OrganizationCollection',
        prefix: 'organization.',
        initialFetchOptions: INITIAL_FETCH_OPTIONS,
        ...props,
    });
}

export function useOrganizationMap() {
    const api = useApi();

    useEffect(() => {
        api.fetchJson(api.url_for('organization.OrganizationCollection'));
    }, [api]);

    return useSelector(ducks.jsonapi.selectObject(['Organization']));
}

export function useOrganizationDataTableFilter() {
    return useDataTableFilter({
        link: 'organization.OrganizationCollection',
        path: 'Organization',
        mapTitle: org => fp.get('attributes.pcc.name', org),
        mapKey: org => fp.get('attributes.pcc.orgUuid', org),
    });
}
