import React from 'react';

import BasePage from 'og/pages/BasePage';
import UserDataTable from 'og/components/datatables/UserDataTable';

export default function UserListPage() {
    return (
        <BasePage subtitle="Admin | Users">
            <UserDataTable />
        </BasePage>
    );
}
