import { components } from '@arborian/narrf';
import { Button, Chip, Tooltip, Link } from '@material-ui/core';
import { useOrganizations } from 'og/datasources/organizations';
import fp from 'lodash/fp';
import * as R from 'react-router-dom';
import { url_for } from 'og/routes';
import { useHubspotAccountUnlink } from 'og/api/useHubspotAccountUnlink';
import { useHubspotAccountLink } from 'og/api/useHubspotAccountLink';

const { dt2 } = components;

export default function OrganizationDataTable() {
    const ds = useOrganizations();
    const refresh = () => ds.refresh();

    const unlinkHubspot = useHubspotAccountUnlink();
    const linkHubspot = useHubspotAccountLink();

    return (
        <dt2.DataTable
            title="Organization List"
            dataSource={ds}
            size="small"
            options={{
                filtering: true,
            }}
        >
            <dt2.Column
                id="hubspot_account"
                title="Hubspot Account"
                field="attributes.hubspot_portal_id"
                render={row => {
                    const account = fp.get('data.attributes.hubspot_portal_id', row);
                    if (account) {
                        return (
                            <Tooltip title="Click to unlink HubSpot account">
                                <Chip label={account} onDelete={ev => unlinkHubspot(ev, row.data)} />
                            </Tooltip>
                        );
                    } else {
                        return (
                            <Button variant="contained" color="primary" onClick={ev => linkHubspot(ev, row.data)}>
                                Link a HubSpot Account
                            </Button>
                        );
                    }
                }}
            />
            <dt2.Column
                id="pcc_org_name"
                title="PointClickCare Organization"
                field="attributes.pcc.name"
                render={row => (
                    <Link component={R.Link} to={url_for('organization', { orgId: row.id })}>
                        {fp.get('attributes.pcc.name', row.data)}
                    </Link>
                )}
            />
            <dt2.Column id="created" type="date" title="Created" field="attributes.created" filtering={false} />
            <dt2.Action name="refresh" free onClick={refresh} tooltip="Refresh" icon="refresh" />
        </dt2.DataTable>
    );
}
