import React, { useState } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';

const defaultValues = {
    type: 'PatientFile',
    includeAll: false,
};

export default function FileUploadDialog({ dialogRef }) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState(defaultValues);
    const [patient, setPatient] = useState(null);

    const handleSubmit = values => {
        resolve(values);
        setResolve(null);
        setPatient(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
        setPatient(null);
    };

    dialogRef.current = ({ patient = null }) => {
        return new Promise((resolve, reject) => {
            setPatient(patient);
            setInitialValues(initialValues);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
                {({ touched, values, errors, setFieldValue }) => (
                    <Form>
                        <DialogTitle>
                            Upload file to{' '}
                            <b>{patient ? patient.attributes.last_name + ', ' + patient.attributes.first_name : ''}</b>
                        </DialogTitle>

                        <DialogContent>
                            <input
                                id="file"
                                name="file"
                                type="file"
                                onChange={event => {
                                    setFieldValue('file', event.currentTarget.files[0]);
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
