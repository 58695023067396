import { components, useApi } from '@arborian/narrf';
import { Refresh, Autorenew, ContactPhone } from '@material-ui/icons';
import { Avatar, Tooltip, Link } from '@material-ui/core';
import * as R from 'react-router-dom';
import { useFacilities } from 'og/datasources/facilities';
import { url_for } from 'og/routes';
import fp from 'lodash/fp';
import { useSnackbar } from 'notistack';
import { useFacilityStyles } from 'og/styles';

const { dt2 } = components;

function FacilityActions({ facility, enqueueSnackbar }) {
    const api = useApi();
    const classes = useFacilityStyles();

    const active = {
        hs: fp.get('attributes.auto_push_to_hubspot', facility),
        pcc: fp.get('attributes.sync_patient_contacts', facility),
    };

    const titles = {
        hs: active.hs ? 'Disable auto-sync to HubSpot' : 'Enable auto-sync to Hubspot',
        pcc: active.pcc ? "Disable patients' contacts sync" : "Enable patients' contacts sync",
    };
    const toggle = {
        hs: async ev => {
            await api.fetchJson(facility.links.self, {
                method: 'PATCH',
                json: { data: fp.set('attributes.auto_push_to_hubspot', !active.hs, facility) },
            });
        },
        pcc: async ev => {
            try {
                const activePCC = !active.pcc;
                await api.fetchJson(facility.links.self, {
                    method: 'PATCH',
                    json: { data: fp.set('attributes.sync_patient_contacts', activePCC, facility) },
                });
                if (activePCC) {
                    enqueueSnackbar("Fetching patients' contacts has been queued.", {
                        variant: 'success',
                        persist: false,
                    });
                }
            } catch (err) {
                console.log(err);
                enqueueSnackbar("Fetching patients' contacts has failed. Please try again.", {
                    variant: 'error',
                    persist: false,
                });
            }
        },
    };

    return (
        <div className={classes.facilityActions}>
            <Tooltip title={titles.pcc}>
                <Avatar
                    variant="rounded"
                    onClick={toggle.pcc}
                    className={active.pcc ? classes.activePCC : classes.inactive}
                >
                    <ContactPhone />
                </Avatar>
            </Tooltip>
            <Tooltip title={titles.hs}>
                <Avatar
                    variant="rounded"
                    onClick={toggle.hs}
                    className={active.hs ? classes.activeHS : classes.inactive}
                >
                    <Autorenew />
                </Avatar>
            </Tooltip>
        </div>
    );
}

export default function FacilityDataTable({ organization }) {
    const api = useApi();
    const ds = useFacilities(organization);

    const refresh = () => ds.fetch();
    const { enqueueSnackbar } = useSnackbar();

    const refreshFac = (ev, row) => {
        try {
            let res = api.fetchJson(row.data.links.refresh_from_pcc, {
                method: 'POST',
            });
            enqueueSnackbar("Fetching facility's patients has been queued.", {
                variant: 'success',
                persist: false,
            });
            return res;
        } catch (err) {
            console.log(err);
            enqueueSnackbar("Fetching facility's patients has failed. Please try again.", {
                variant: 'error',
                persist: false,
            });
        }
    };

    return (
        <dt2.DataTable
            title="Facility List"
            dataSource={ds}
            size="small"
            options={{
                filtering: true,
            }}
        >
            <dt2.Column
                id="facility_name"
                title="Facility Name"
                field="attributes.pcc.facilityName"
                editable={false}
                render={row => {
                    const params = new URLSearchParams();
                    params.append('patient.filter[attributes.facility_id]', JSON.stringify({ $in: [row.id] }));

                    return (
                        <Link component={R.Link} to={url_for('patients', {}) + '?' + params.toString()}>
                            {fp.get('attributes.pcc.facilityName', row.data)}
                        </Link>
                    );
                }}
            />
            <dt2.Column
                id="fac-actions"
                filtering={false}
                render={row => {
                    if (organization.attributes.hubspot_is_linked) {
                        return <FacilityActions facility={row.data} enqueueSnackbar={enqueueSnackbar} />;
                    }

                    return null;
                }}
            />
            <dt2.Action name="refresh-fac" onClick={refreshFac} tooltip="Refresh" icon={<Refresh />} />
            <dt2.Action name="refresh" free onClick={refresh} tooltip="Refresh" icon={<Refresh />} />
        </dt2.DataTable>
    );
}
