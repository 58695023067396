import fp from 'lodash/fp';
import { format, parseISO } from 'date-fns/fp';

export function renderDate(value) {
    if (!value) {
        return null;
    }

    if (fp.isString(value)) {
        try {
            value = parseISO(value);
        } catch (e) {
            console.error('Error parsing date', value, e);
        }
    }

    if (fp.isDate(value)) {
        try {
            value = format('MM/dd/yyyy', value);
        } catch (e) {
            console.error('Error formatting date', value, e);
            value = value.toJSON();
        }
    }

    return value;
}

export function renderDifference(row, pccKey, hubspotKey, classes, renderer = null) {
    let pccField = fp.get(`attributes.pcc.${pccKey}`, row.data);
    let hubspotField = fp.get(`attributes.hubspot.properties.${hubspotKey}`, row.data);

    if (pccKey === 'email' && hubspotKey === 'email') {
        pccField = pccField ? pccField.toLowerCase() : '-';
        hubspotField = hubspotField ? hubspotField.toLowerCase() : '-';
    }
    const pccRender = renderer ? renderer(pccField) : pccField;
    const hubspotFieldsRender = renderer ? renderer(hubspotField) : hubspotField;

    if (pccField === hubspotField) {
        return <span>{pccRender ?? '-'}</span>;
    }

    return (
        <>
            <div>
                <div className={classes.pccBg}>{pccRender ?? '-'}</div>
                <div className={classes.hubspotBg}>{hubspotFieldsRender ?? '-'}</div>
            </div>
        </>
    );
}
