import React, { useState, useEffect } from 'react';
import * as R from 'react-router-dom';

import { makeStyles, Link } from '@material-ui/core';

import { url_for } from 'og/routes';
import AppBar from 'og/components/AppBar';
import NavDrawer from 'og/components/NavDrawer';
import AutoLogout from 'og/components/AutoLogout';

const useStyles = makeStyles(theme => ({
    root: {
        '& h1': {
            ...theme.typography.h1,
            margin: 0,
        },
        '& h2': theme.typography.h2,
        '& h3': theme.typography.h3,
        '& h4': {
            ...theme.typography.h4,
            margin: 0,
        },
        '& h5': theme.typography.h5,
        '& h6': theme.typography.h6,
        '& p': theme.typography.body1,
        'height': '100vh',
        '& a.green-link': {
            color: '#459e76',
        },
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        padding: theme.spacing(0, 1),
        textAlign: 'center',
        backgroundColor: '#F6F7FF',
        height: theme.spacing(7),
        display: 'flex',
        justifyContent: 'space-between',
    },
    contentContainer: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(8),
    },
    contentArea: {
        // maxWidth: 1280,
        maxWidth: 1400,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default function BasePage({ title = null, subtitle, children }) {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const brandName = 'PointClickCare + HubSpot Connector';
    const idleTimeout = 15 * 60 * 1000;

    useEffect(() => {
        if (title) {
            document.title = `${title} | ${brandName}`;
        } else {
            document.title = `${brandName}`;
        }
    }, [title]);
    return (
        <div className={classes.root}>
            <AutoLogout timeout={idleTimeout} />
            <NavDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
            <AppBar title={title} subtitle={subtitle} onToggleDrawer={() => setDrawerOpen(!drawerOpen)} />
            <div className={classes.contentContainer}>
                <div className={classes.contentArea}>{children}</div>
            </div>
            <footer className={classes.footer}>
                <p>&copy; 2023 Arborian Consulting, LLC. All Rights Reserved.</p>
                <p>
                    <Link component={R.Link} to={url_for('privacy')}>
                        Privacy
                    </Link>
                    &nbsp;|&nbsp;
                    <Link component="a" href="mailto:support@arborian.com">
                        Support
                    </Link>
                    &nbsp;|&nbsp;
                    <Link component={R.Link} to={url_for('login') + '?show_google=1'}>
                        Admin Login
                    </Link>
                </p>
            </footer>
        </div>
    );
}
