import { components } from '@arborian/narrf';
import { hsIsLinked } from 'og/components/actions/StatusActions';
import { selectionCapabilities } from 'og/components/actions/actions';

const { Action } = components.dt2;

export function UpdateHSAction({ orgs, action }) {
    return (
        <Action id="update-hubspot" onClick={action} tooltip="Create / Update HubSpot contact" icon="add_comment">
            {rows => {
                const cap = selectionCapabilities(rows);
                const hs = hsIsLinked(rows, orgs);
                return { hidden: !cap.canUpdateHS || !hs };
            }}
        </Action>
    );
}

export function SplitAction({ orgs, action }) {
    return (
        <Action
            id="split-records"
            onClick={action}
            tooltip="Split Hubspot and PointClickCare Records"
            icon="call_split"
        >
            {rows => {
                const caps = selectionCapabilities(rows);
                return { hidden: !caps.canSplit || !hsIsLinked(rows, orgs) };
            }}
        </Action>
    );
}

export function MergeAction({ orgs, action }) {
    return (
        <Action id="merge" onClick={action} tooltip="Merge selected records" icon="call_merge">
            {rows => ({ hidden: rows.length !== 2 || !hsIsLinked(rows, orgs) })}
        </Action>
    );
}

export function UpdatePCCAction({ orgs, action }) {
    return (
        <Action id="update-pcc" onClick={action} tooltip="Update a pending patient" icon="assignment_ind">
            {rows => {
                const caps = selectionCapabilities(rows);
                return { hidden: !caps.canUpdatePCC || !hsIsLinked(rows, orgs) };
            }}
        </Action>
    );
}

export function UploadFileAction({ orgs, action }) {
    return (
        <Action id="upload-file" onClick={action} tooltip="Upload a file to a patient" icon="cloud_upload">
            {rows => ({ hidden: rows.length > 1 || !hsIsLinked(rows, orgs) })}
        </Action>
    );
}
