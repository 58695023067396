import { components } from '@arborian/narrf';
import fp from 'lodash/fp';
import { useFacilityDataTableFilter } from 'og/datasources/facilities';
import { useGenderDataTableFilter } from 'og/datasources/genders';
import { usePatientStatusDataTableFilter } from 'og/datasources/patient-statuses';
import { renderDate, renderDifference } from 'og/utils/render';
import { usePatientStyles } from 'og/styles';

const { Column } = components.dt2;

export function FacilityColumn({ ...props }) {
    const [lookupFacilities, facilities] = useFacilityDataTableFilter();
    return (
        <Column
            id="facility_id"
            title="Facility"
            field="attributes.facility_id"
            lookup={lookupFacilities}
            render={row => {
                const facilityId = fp.get('attributes.facility_id', row.data);
                return facilities?.[facilityId] ?? '-';
            }}
            {...props}
        />
    );
}

export function PatientStatusColumn({ ...props }) {
    const classes = usePatientStyles();
    const [lookupPatientStatuses] = usePatientStatusDataTableFilter();
    return (
        <Column
            id="patient_status"
            title="Status"
            field="attributes.pcc.patientStatus"
            lookup={lookupPatientStatuses}
            render={row => renderDifference(row, 'patientStatus', 'pcc_patient_status', classes)}
            {...props}
        />
    );
}

export function FirstNameColumn({ ...props }) {
    const classes = usePatientStyles();
    return (
        <Column
            id="last_name"
            title="Last"
            field="attributes.last_name"
            render={row => renderDifference(row, 'lastName', 'lastname', classes)}
            {...props}
        />
    );
}

export function LastNameColumn({ ...props }) {
    const classes = usePatientStyles();
    return (
        <Column
            id="patient_first_name"
            title="First"
            field="attributes.first_name"
            render={row => renderDifference(row, 'firstName', 'firstname', classes)}
            {...props}
        />
    );
}

export function EmailColumn({ ...props }) {
    const classes = usePatientStyles();
    return (
        <Column
            id="email"
            title="Email"
            field="attributes.email"
            render={row => renderDifference(row, 'email', 'email', classes)}
            {...props}
        />
    );
}

export function PhoneColumn({ ...props }) {
    const classes = usePatientStyles();
    return (
        <Column
            id="phone"
            title="Phone"
            field="attributes.phone"
            render={row => renderDifference(row, 'homePhone', 'phone', classes)}
            {...props}
        />
    );
}

export function DOBColumn({ ...props }) {
    const classes = usePatientStyles();
    return (
        <Column
            id="dob"
            title="DOB"
            field="attributes.dob"
            type="date"
            render={row => renderDifference(row, 'birthDate', 'pcc_patient_dob', classes, renderDate)}
            filtering={false}
            {...props}
        />
    );
}

export function GenderColumn({ ...props }) {
    const [lookupGenders] = useGenderDataTableFilter();
    const classes = usePatientStyles();
    return (
        <Column
            id="gender"
            title="Gender"
            field="attributes.pcc.gender"
            lookup={lookupGenders}
            render={row => renderDifference(row, 'gender', 'pcc_patient_gender', classes)}
            {...props}
        />
    );
}

export function RelatedColumn({ ...props }) {
    const [lookupGenders] = useGenderDataTableFilter();
    const classes = usePatientStyles();
    return (
        <Column
            id="gender"
            title="Gender"
            field="attributes.pcc.gender"
            lookup={lookupGenders}
            render={row => renderDifference(row, 'gender', 'pcc_patient_gender', classes)}
            {...props}
        />
    );
}
