import useResources from './resources';
import { useEffect } from 'react';
import { ducks, useApi } from '@arborian/narrf';
import { useSelector } from 'react-redux';

const INITIAL_FETCH_OPTIONS = {
    filter: {},
    page: { number: 0, size: 20 },
    sort: { field: 'attributes.last_name', direction: 'asc' },
};

export function usePatientContact(contactId) {
    const api = useApi();

    useEffect(() => {
        api.fetchJson(api.url_for('patient_contact.PatientContact', { contact_id: contactId }));
    }, [api, contactId]);

    return useSelector(ducks.jsonapi.selectObject(['PatientContact', contactId]));
}

export function usePatientsContacts(contact, props) {
    return useResources({
        link: 'patient_contact.PatientContactCollection',
        prefix: 'patient_contacts.',
        initialFetchOptions: INITIAL_FETCH_OPTIONS,
        ...props,
    });
}
