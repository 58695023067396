import React, { useRef, useCallback } from 'react';
import { createBrowserHistory } from 'history';
import { MuiThemeProvider, CssBaseline, IconButton } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { Provider as ReduxProvider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@date-io/date-fns';

import { reducer, ApiProvider, ErrorBoundary } from '@arborian/narrf';
import Router from 'og/components/Router';
import '@fontsource/montserrat';

const API_ROOT = process.env.REACT_APP_API_ROOT;
const store = configureStore({ reducer });
const history = createBrowserHistory();

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontFamily: '"Montserrat"',
    },
    palette: {
        og: {
            pcc: '#97CC04',
            hubspot: '#FF5C35',
            white: '#FFFFFF',
            upload: '#FFC135',
        },
    },
    overrides: {},
    constants: {},
});

function App() {
    console.log({ API_ROOT });

    // START notiStack config
    const notistackRef = useRef();
    const handleDismiss = useCallback(
        key => {
            notistackRef.current.closeSnackbar(key);
        },
        [notistackRef],
    );
    const dismissAction = useCallback(
        key => (
            <IconButton onClick={() => handleDismiss(key)}>
                <Cancel />
            </IconButton>
        ),
        [handleDismiss],
    );
    // END notistack config

    return (
        <ErrorBoundary fallback={errorScreen}>
            <ReduxProvider store={store}>
                <ApiProvider
                    ui_home="/"
                    ui_login="/login"
                    history={history}
                    api_root={API_ROOT}
                    client_id={null} // loaded from /legacy/constants
                    onError={console.log}
                >
                    <CssBaseline />
                    <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={DateFnsAdapter}>
                            <SnackbarProvider
                                ref={notistackRef}
                                action={dismissAction}
                                anchorOrigin={{
                                    horizontal: 'left',
                                    vertical: 'top',
                                }}
                            >
                                <Router history={history} />
                            </SnackbarProvider>
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </ApiProvider>
            </ReduxProvider>
        </ErrorBoundary>
    );
}

export const errorScreen = () => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <h2>Something went wrong.</h2>
        <p>
            Please refresh the page and try again. If this issue continues, please contact{' '}
            <a href="mailto:help@arborianhealth.com">help@arborianhealth.com</a>.
        </p>
    </div>
);

export default App;
