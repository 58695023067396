import useResources from './resources';
import { useEffect } from 'react';
import { ducks, useApi } from '@arborian/narrf';
import { useSelector } from 'react-redux';

const INITIAL_FETCH_OPTIONS = {
    filter: {},
    page: { number: 0, size: 20 },
    sort: { field: 'attributes.last_name', direction: 'asc' },
};

export function usePatient(patientId) {
    const api = useApi();

    useEffect(() => {
        api.fetchJson(api.url_for('patient.Patient', { patient_id: patientId }));
    }, [api, patientId]);

    return useSelector(ducks.jsonapi.selectObject(['Patient', patientId]));
}

export function usePatients(patient, props) {
    return useResources({
        link: 'patient.PatientCollection',
        prefix: 'patient.',
        initialFetchOptions: INITIAL_FETCH_OPTIONS,
        ...props,
    });
}
