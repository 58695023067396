import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import { ApiRoute } from '@arborian/narrf';

import routes from 'og/routes';

import HomePage from 'og/pages/HomePage';
import PrivacyPage from 'og/pages/PrivacyPage';
import LoginPage from 'og/pages/LoginPage';
import LogoutPage from 'og/pages/LogoutPage';
import RegisterPage from 'og/pages/RegisterPage';
import CallbackPage from 'og/pages/CallbackPage';
import OrganizationListPage from 'og/pages/OrganizationListPage';
import OrganizationPage from 'og/pages/OrganizationPage';
import UserListPage from 'og/pages/UserListPage';
import PatientListPage from '../pages/PatientListPage';
import PatientContactListPage from '../pages/PatientContactListPage';
import HubSpotContactListPage from 'og/pages/HubSpotContactListPage';
import DialogProvider from 'og/components/DialogProvider';

export default function Router() {
    return (
        <BrowserRouter>
            <DialogProvider>
                <Switch>
                    {/* API Routes */}
                    <ApiRoute exact path={routes.home.path} component={HomePage} />
                    <ApiRoute exact path={routes.privacy.path} component={PrivacyPage} />
                    <ApiRoute exact path={routes.login.path} component={LoginPage} />
                    <ApiRoute exact path={routes.logout.path} component={LogoutPage} />
                    <ApiRoute exact path={routes.register.path} component={RegisterPage} />
                    <ApiRoute exact path={routes.callback.path} component={CallbackPage} />
                    <ApiRoute authorized exact path={routes.organizations.path} component={OrganizationListPage} />
                    <ApiRoute authorized exact path={routes.organization.path} component={OrganizationPage} />
                    <ApiRoute authorized exact path={routes.patients.path} component={PatientListPage} />
                    <ApiRoute
                        authorized
                        exact
                        path={routes.patients_contacts.path}
                        component={PatientContactListPage}
                    />
                    <ApiRoute authorized exact path={routes.users.path} component={UserListPage} />
                    <ApiRoute authorized exact path={routes.contacts.path} component={HubSpotContactListPage} />
                    {/* Static Routes */}
                    {/* <StaticRoute path='/about.html' /> */}
                </Switch>
            </DialogProvider>
        </BrowserRouter>
    );
}
