import { components, useApi } from '@arborian/narrf';
import { Avatar, Tooltip } from '@material-ui/core';
import { useHubSpotContacts } from 'og/datasources/hubspot-contacts';
import { PersonAddRounded } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { usePatientStyles } from 'og/styles';

const { dt2 } = components;

function StatusActions({ row, ds }) {
    const classes = usePatientStyles();
    const { enqueueSnackbar } = useSnackbar();

    const api = useApi();

    const createPendingPatient = async (ev, hubspotContact) => {
        const url = api.url_for('contact.HubspotContactToPCC', {
            contact_id: hubspotContact.id,
        });

        try {
            await api.fetchJson(url, {
                method: 'POST',
            });
            enqueueSnackbar("Pending patient has been created. Please go to the patient's table.", {
                variant: 'success',
                persist: false,
            });

            await ds.fetch();
        } catch (err) {
            enqueueSnackbar('Creation of a pending patient failed. Please try again.', {
                variant: 'error',
                persist: false,
            });
        }
    };

    return (
        <div className={classes.statusActions}>
            <Tooltip title="Create a PCC Pending Patient">
                <Avatar variant="rounded" className={classes.pccBg} onClick={ev => createPendingPatient(ev, row.data)}>
                    <PersonAddRounded />
                </Avatar>
            </Tooltip>
        </div>
    );
}

export default function HubSpotContactsDataTable() {
    const ds = useHubSpotContacts();
    const refresh = () => ds.fetch();
    return (
        <dt2.DataTable
            title="HubSpot Contact List"
            dataSource={ds}
            size="small"
            options={{
                filtering: true,
            }}
        >
            <dt2.Column id="status_actions" filtering={false} render={row => <StatusActions row={row} ds={ds} />} />
            <dt2.Column id="properties_email" title="Email" field="attributes.email" />
            <dt2.Column id="properties_lastname" title="Lastname" field="attributes.last_name" />
            <dt2.Column id="properties_firstname" title="Firstname" field="attributes.first_name" />
            <dt2.Action name="refresh" free onClick={refresh} tooltip="Refresh" icon="refresh" />
        </dt2.DataTable>
    );
}
