import { useRelated } from './related';
import { useDataTableFilter } from './datatable-filter';
import fp from 'lodash/fp';

const INITIAL_FETCH_OPTIONS = {
    filter: {},
    page: { number: 0, size: 20 },
    sort: { field: 'attributes.created', direction: 'desc' },
};

export function useFacilities(organization, props) {
    return useRelated(organization, 'facilities', {
        prefix: 'facility.',
        initialFetchOptions: INITIAL_FETCH_OPTIONS,
        ...props,
    });
}

export function useFacilityDataTableFilter() {
    return useDataTableFilter({
        link: 'facility.FacilityCollection',
        path: 'Facility',
        mapTitle: facility => fp.get('attributes.pcc.facilityName', facility),
        mapKey: facility => fp.get('id', facility),
    });
}
