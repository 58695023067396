import { makeStyles } from '@material-ui/core';

export const usePatientStyles = makeStyles(theme => ({
    root: {
        '& #create-ppts .MuiIcon-root': {
            color: theme.palette.og.pcc,
        },
        '& #create-cts .MuiIcon-root': {
            color: theme.palette.og.hubspot,
        },
        '& #push-hs-to-pcc .MuiIcon-root': {
            color: theme.palette.og.hubspot,
        },
        '& #push-pcc-to-hs .MuiIcon-root': {
            color: theme.palette.og.pcc,
        },
    },
    pcc: {
        color: theme.palette.og.pcc,
    },
    hubspot: {
        color: theme.palette.og.hubspot,
    },
    pccBg: {
        backgroundColor: theme.palette.og.pcc,
        color: theme.palette.og.white,
        textAlign: 'center',
        fontWeight: 'bold',
        borderRadius: 5,
        marginBottom: 5,
        paddingRight: 5,
        paddingLeft: 5,
    },
    hubspotBg: {
        backgroundColor: theme.palette.og.hubspot,
        color: theme.palette.og.white,
        textAlign: 'center',
        fontWeight: 'bold',
        borderRadius: 5,
        paddingRight: 5,
        paddingLeft: 5,
    },
    splitBg: {
        backgroundColor: theme.palette.primary.main,
    },
    uploadBg: {
        backgroundColor: theme.palette.og.upload,
    },
    statusActions: {
        'display': 'flex',
        '& .MuiAvatar-root': {
            margin: theme.spacing(0, 0.25),
            cursor: 'pointer',
        },
    },
}));

export const useFacilityStyles = makeStyles(theme => ({
    activeHS: {
        cursor: 'pointer',
        backgroundColor: theme.palette.og.hubspot,
    },
    activePCC: {
        cursor: 'pointer',
        backgroundColor: theme.palette.og.pcc,
    },
    inactive: {
        cursor: 'pointer',
    },
    facilityActions: {
        'display': 'flex',
        '& .MuiAvatar-root': {
            margin: theme.spacing(0, 0.25),
            cursor: 'pointer',
        },
    },
}));
