import { ducks, useApi } from '@arborian/narrf';
import { useEffect } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export function useDataTableFilter({ link = null, path = null, mapTitle = null, mapKey = null, mapReference = null }) {
    const api = useApi();

    useEffect(() => {
        api.fetchAllJsonApi(api.url_for(link));
    }, [api, link]);

    const selector = createSelector(ducks.jsonapi.selectObject(path), items => {
        if (!items) {
            return [{}, {}];
        }

        const lookupMap = {};
        const referenceMap = {};

        for (const [k, v] of Object.entries(items)) {
            try {
                const title = mapTitle ? mapTitle(v) : JSON.stringify(v, null, 2);
                const key = mapKey ? mapKey(v) : k;
                const reference = mapReference ? mapReference(v) : k;

                if (title !== null && title !== undefined) {
                    lookupMap[key] = title;
                    referenceMap[reference] = title;
                }
            } catch (err) {
                console.error(err);
            }
        }

        return [lookupMap, referenceMap];
    });

    return useSelector(selector);
}
