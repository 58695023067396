import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import fp from 'lodash/fp';

import { makeStyles } from '@material-ui/core';

import { useApi, ducks } from '@arborian/narrf';

import { url_for } from 'og/routes';
import BasePage from './BasePage';

import LoginButtons from 'og/components/LoginButtons';
import { selectUserOrganization } from 'og/selectors';

const useStyles = makeStyles(theme => ({
    header: {
        'textAlign': 'center',
        'padding': theme.spacing(2),
        'margin': '0 auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 800,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& img': {
            maxWidth: '100%',
        },
    },
    body: {
        'display': 'flex',
        'flexDirection': 'column',
        'alignItems': 'center',
        'textAlign': 'center',
        '& .MuiButtonBase-root': {
            margin: theme.spacing(5),
            padding: theme.spacing(2, 5),
        },
    },
}));

function AnonContent() {
    return (
        <LoginButtons intent="login">
            <div>
                <small>
                    Don't have an account yet? <br />
                    Register <a href={url_for('register')}>here</a>
                </small>
            </div>
        </LoginButtons>
    );
}

function AuthContent({ userinfo }) {
    const api = useApi();
    const history = useHistory();
    const name = fp.get('name', userinfo);
    const isSuperuser = fp.some(s => s === '__admin__', userinfo.scopes);
    const org = useSelector(selectUserOrganization);
    useEffect(() => {
        api.fetchAllJsonApi(api.url_for('organization.OrganizationCollection'));
    }, [api]);
    console.log('Auth', { scopes: userinfo.scopes, isSuperuser, org });
    useEffect(() => {
        if (isSuperuser) {
            history.push(url_for('organizations'));
        } else if (org) {
            history.push(url_for('organization', { orgId: org.id }));
        }
    }, [isSuperuser, history, org]);
    return (
        <>
            <h4>Welcome back, {name}!</h4>
        </>
    );
}

export default function HomePage() {
    const classes = useStyles();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const isAnon = !userinfo;
    console.log('Home', { userinfo });

    return (
        <BasePage>
            <div className={classes.header}>
                <img alt="PoingClickCare + HubSpot CRM Connector" src="/s/logo/svg/logo-no-background.svg" />
                <p>Seamlessly bridging the gap between HubSpot CRM and PointClickCare</p>
            </div>
            <div className={classes.body}>{isAnon ? <AnonContent /> : <AuthContent userinfo={userinfo} />}</div>
        </BasePage>
    );
}
