import { Avatar, Tooltip } from '@material-ui/core';
import { AddComment, CallSplit, AssignmentIndRounded, CloudUpload } from '@material-ui/icons';
import fp from 'lodash/fp';

export function hsIsLinked(patients, orgs) {
    return patients.every(patient => {
        const orgId = fp.get('data.attributes.organization_id', patient);
        if (orgs && orgId in orgs) {
            return fp.get('attributes.hubspot_is_linked', orgs[orgId]);
        }

        return false;
    });
}

export function UpdateHS({ row, action, hsIsLinked, canUpdateHS, ...props }) {
    return (
        canUpdateHS &&
        hsIsLinked && (
            <Tooltip title="Create / Update HubSpot">
                <Avatar variant="rounded" onClick={ev => action(ev, [row])} {...props}>
                    <AddComment />
                </Avatar>
            </Tooltip>
        )
    );
}

export function Split({ row, action, hsIsLinked, canSplit, ...props }) {
    return (
        canSplit &&
        hsIsLinked && (
            <Tooltip title="Split Hubspot and PointClickCare Records">
                <Avatar variant="rounded" onClick={ev => action(ev, [row])} {...props}>
                    <CallSplit />
                </Avatar>
            </Tooltip>
        )
    );
}

export function UpdatePCC({ row, action, hsIsLinked, canUpdatePCC, ...props }) {
    return (
        canUpdatePCC &&
        hsIsLinked && (
            <Tooltip title="Update PCC Pending Patient">
                <Avatar variant="rounded" onClick={ev => action(ev, [row])} {...props}>
                    <AssignmentIndRounded />
                </Avatar>
            </Tooltip>
        )
    );
}

export function UploadFile({ row, action, hasPCCPatient, hasHSContact, ...props }) {
    return (
        hasPCCPatient &&
        hasHSContact && (
            <Tooltip title="Upload a file to a patient">
                <Avatar variant="rounded" onClick={ev => action(ev, [row])} {...props}>
                    <CloudUpload />
                </Avatar>
            </Tooltip>
        )
    );
}
