import React from 'react';

import BasePage from 'og/pages/BasePage';
import OrganizationDataTable from 'og/components/datatables/OrganizationDataTable';

export default function OrganizationListPage() {
    return (
        <BasePage title="Organizations">
            <OrganizationDataTable></OrganizationDataTable>
        </BasePage>
    );
}
