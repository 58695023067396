import React from 'react';

import { makeStyles } from '@material-ui/core';

import BasePage from 'og/pages/BasePage';

import { url_for } from 'og/routes';
import LoginButtons from 'og/components/LoginButtons';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
    },
}));

export default function RegisterPage() {
    const classes = useStyles();

    return (
        <BasePage title="Register">
            <div className={classes.root}>
                <h3>Please Sign In</h3>
                <p>Click below to register using PointClickCare</p>

                <LoginButtons intent="register">
                    <div>
                        <small>
                            Already have an account? <br />
                            Sign in <a href={url_for('login')}>here</a>
                        </small>
                    </div>
                </LoginButtons>
            </div>
        </BasePage>
    );
}
