import React from 'react';
import * as R from 'react-router-dom';
import { AppBar as MUIAppBar, IconButton, Toolbar, makeStyles } from '@material-ui/core';

import { url_for } from 'og/routes';
import * as I from '@material-ui/icons';

import AuthInfo from './AuthInfo';

const useStyles = makeStyles(theme => ({
    root: {
        '& img': {
            height: theme.spacing(5),
            marginRight: theme.spacing(1),
        },
    },
    title: {
        flexGrow: 1,
        margin: 0,
    },
    subtitle: {},
}));

export default function AppBar({ onToggleDrawer, title, subtitle }) {
    const classes = useStyles();

    return (
        <MUIAppBar color="inherit" position="static">
            <Toolbar className={classes.root}>
                <IconButton onClick={onToggleDrawer} color="inherit">
                    <I.Menu />
                </IconButton>
                <R.Link to={url_for('home')}>
                    <img alt="PoingClickCare + HubSpot CRM Connector" src="/s/logo/svg/logo-no-background.svg" />
                </R.Link>
                <h6 className={classes.title}>
                    {title}
                    {subtitle && <span className={classes.subtitle}> | {subtitle}</span>}
                </h6>
                <AuthInfo />
            </Toolbar>
        </MUIAppBar>
    );
}
