import React from 'react';

import { makeStyles } from '@material-ui/core';

import { url_for } from 'og/routes';
import BasePage from './BasePage';

import LoginButtons from 'og/components/LoginButtons';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
    },
}));

export default function LoginPage() {
    const classes = useStyles();

    return (
        <BasePage title="Sign in">
            <div className={classes.root}>
                <h3>Please Sign In</h3>
                <p>Click below to sign in with PointClickCare</p>

                <LoginButtons intent="login">
                    <div>
                        <small>
                            Don't have an account yet? <br />
                            Register <a href={url_for('register')}>here</a>
                        </small>
                    </div>
                </LoginButtons>
            </div>
        </BasePage>
    );
}
