import fp from 'lodash/fp';

const notistack_options = {
    success: {
        variant: 'success',
        persist: false,
    },
    error: {
        variant: 'error',
        persist: false,
    },
};

export const commonCapabilities = patient => {
    const pcc = fp.get('attributes.pcc', patient);
    const hs = fp.get('attributes.hubspot', patient);
    const hasPCCPatient = !!fp.get('patientId', pcc);
    const hasPCCPendingPatient = !!fp.get('pendingPatientId', pcc);
    const hasPCCPatientContact = !!fp.get('contactId', pcc);
    const hasHSContact = !!fp.get('id', hs);
    const hasHSLinkData =
        !fp.isEmpty(fp.get('properties.pcc_facility_name', hs)) &&
        !fp.isEmpty(fp.get('properties.pcc_patient_status', hs)) &&
        !fp.isEmpty(fp.get('properties.pcc_patient_gender', hs)) &&
        !fp.isEmpty(fp.get('properties.pcc_patient_dob', hs));
    const canUpdateHS = hasPCCPatient || hasPCCPendingPatient || hasPCCPatientContact;
    const canSplit = (hasPCCPatient || hasPCCPendingPatient) && hasHSContact;
    const canUpdatePCC = hasPCCPatient && hasPCCPendingPatient;
    return {
        hasPCCPatient,
        hasPCCPendingPatient,
        hasHSContact,
        hasHSLinkData,
        canUpdateHS,
        canUpdatePCC,
        canSplit,
    };
};

export const selectionCapabilities = rows => {
    const caps = fp.pipe([fp.map('data'), fp.map(commonCapabilities)])(rows);
    return {
        canUpdateHS: fp.every(Boolean, fp.map('canUpdateHS', caps)),
        canSplit: fp.every(Boolean, fp.map('canSplit', caps)),
        canUpdatePCC: fp.every(Boolean, fp.map('canUpdatePCC', caps)),
    };
};

export const commonActions = (api, ds, uploadDialog, enqueueSnackbar) => ({
    updateHubspot: async (ev, rows) => {
        const promises = fp.map(
            row =>
                api.fetchJson(row.data.links.hubspotUpdate, {
                    method: 'POST',
                }),
            rows,
        );
        await Promise.all(promises)
            .then(() => {
                enqueueSnackbar('Hubspot contact has been updated.', notistack_options.success);
            })
            .catch(err => {
                enqueueSnackbar("Hubspot contact hasn't been updated.", notistack_options.error);
            });
    },
    splitRecords: async (ev, rows) => {
        const promises = fp.map(
            row =>
                api.fetch(row.data.links.split, {
                    method: 'POST',
                }),
            rows,
        );
        await Promise.all(promises);
        await ds.fetch();
    },
    mergeRecords: async (ev, rows) => {
        const withPcc = fp.filter(row => !!fp.get('attributes.pcc.facId', row.data), rows);
        const withoutPcc = fp.filter(row => !fp.get('attributes.pcc.facId', row.data), rows);

        const dest = withPcc[0];
        const src = withoutPcc[0];

        const resp = await api.fetchJson(dest.data.links.merge, {
            method: 'POST',
            json: { data: { type: 'Patient', id: src.id } },
        });

        await ds.fetch();
        return resp;
    },
    updatePCC: async (ev, rows) => {
        const promises = fp.map(
            row =>
                api.fetchJson(row.data.links.PCCUpdate, {
                    method: 'PUT',
                }),
            rows,
        );
        await Promise.all(promises);
    },
    uploadFile: async (ev, rows) => {
        const patient = rows[0].data;
        const response = await uploadDialog({
            patient: patient,
            count: rows.length,
        });
        if (!response) return;
        let formBody = new FormData();
        formBody.append('patient_id', patient.id);
        formBody.append('file', response.file);

        await api.fetchJson(api.url_for('patient.UploadFile'), {
            method: 'POST',
            body: formBody,
        });
        enqueueSnackbar('File uploading has been enqueued', {
            persist: false,
            variant: 'success',
        });
    },
});
