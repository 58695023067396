import useResources from './resources';
import fp from 'lodash/fp';

export function useRelated(resource, rel, props) {
    return useResources({
        prefix: `res-${rel}.`,
        href: fp.get(['relationships', rel, 'links', 'related'], resource),
        ...props,
    });
}
