import { useEffect, useState } from 'react';
import { useApi } from '@arborian/narrf';
import fp from 'lodash/fp';

const fetchProviders = async (api, setProviders, state) => {
    const redirect_uri = new URL('/callback', window.location.href);
    const authorizeLink = api.authorizeLink({
        redirect_uri,
        intent: 'login',
        state,
    });
    let rv = await fetch(authorizeLink);
    if (rv.status === 300) {
        let data = await rv.json();
        const providers = fp.pipe([fp.get('options'), fp.toPairs, fp.map(([id, attributes]) => ({ id, attributes }))])(
            data,
        );
        setProviders(providers);
    }
};

export function useAuthProviders() {
    const api = useApi();
    const [providers, setProviders] = useState();

    useEffect(() => {
        if (!providers) {
            fetchProviders(api, setProviders, '');
        }
    }, [api, providers, setProviders]);
    return providers;
}
