import React from 'react';

import { components } from '@arborian/narrf';

import FileUploadDialog from 'og/components/dialogs/FileUploadDialog';

export const useDialog = components.useDialog;

export default function DialogProvider({ children }) {
    return (
        <components.DialogProvider
            dialogs={{
                upload: FileUploadDialog,
            }}
        >
            {children}
        </components.DialogProvider>
    );
}
