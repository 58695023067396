export function usePatientStatusDataTableFilter() {
    const genders = {
        Pending: 'Pending',
        New: 'New',
        Current: 'Current',
        Discharged: 'Discharged',
    };

    return [genders, genders];
}
