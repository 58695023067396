import React from 'react';
import { useLocation } from 'react-router-dom';
import fp from 'lodash/fp';

import { makeStyles } from '@material-ui/core';

import { useQuery } from 'og/routes';
import { useAuthProviders } from 'og/datasources/providers';

import PccLoginButton from 'og/components/PccLoginButton';
import BaseLoginButton from 'og/components/BaseLoginButton';

const useStyles = makeStyles(theme => ({
    root: {
        'display': 'flex',
        'flex': 1,
        'flexDirection': 'column',
        'justifyContent': 'center',
        '& .MuiButtonBase-root': {
            margin: theme.spacing(5),
        },
    },
}));

export default function Buttons({ intent = 'login', children }) {
    const providers = useAuthProviders();
    const { state } = useLocation();
    const query = useQuery();
    const classes = useStyles();

    const pcc = fp.find(p => p.attributes.plugin_id === 'pcc', providers);
    const nonPcc = fp.pipe([
        fp.filter(p => p.attributes.plugin_id !== 'pcc'),
        fp.filter(p => {
            const param = `show_${p.attributes.plugin_id}`;
            return !!query.get(param);
        }),
    ])(providers);

    return (
        <div className={classes.root}>
            <div>
                <PccLoginButton provider={pcc} intent={intent} />
            </div>
            {fp.map(
                p => (
                    <div>
                        <BaseLoginButton key={p.id} provider={p} state={state} intent={intent}>
                            Sign in with {p.attributes.label}
                        </BaseLoginButton>
                    </div>
                ),
                nonPcc,
            )}
            {children}
        </div>
    );
}
