import React from 'react';

import BasePage from 'og/pages/BasePage';
import HubSpotContactsDataTable from 'og/components/datatables/HubSpotContactsDataTable';

export default function HubSpotContactListPage() {
    return (
        <BasePage title="HubSpot Contacts">
            <HubSpotContactsDataTable></HubSpotContactsDataTable>
        </BasePage>
    );
}
