import { useLocation } from 'react-router-dom';
import fp from 'lodash/fp';
import { reverse } from 'named-urls';

const routeList = [
    { name: 'home', path: '/' },
    { name: 'privacy', path: '/privacy' },
    { name: 'login', path: '/login' },
    { name: 'logout', path: '/logout' },
    { name: 'register', path: '/register' },
    { name: 'callback', path: '/callback' },
    { name: 'profile', path: '/profile' },
    { name: 'user', path: '/user/:userId' },
    { name: 'users', path: '/user' },
    { name: 'organization', path: '/organization/:orgId' },
    { name: 'organizations', path: '/organization' },
    { name: 'contacts', path: '/contact' },
    // { name: 'patient', path: '/patient/:patId' },
    { name: 'patients', path: '/patient' },
    { name: 'patients_contacts', path: '/patient-contact' },
];

export const routes = fp.transform(
    (acc, r) => {
        acc[r.name] = r;
    },
    {},
    routeList,
);

export const useQuery = () => new URLSearchParams(useLocation().search);

export const routeByType = fp.transform(
    routeList,
    (acc, r) => {
        if (r.type) {
            acc[r.type] = r;
        }
    },
    {},
);

export const url_for = (route, params) => {
    try {
        let { path } = routes[route];
        return reverse(path, params);
    } catch (e) {
        console.error('Error looking up route', route, params);
        throw e;
    }
};

export default routes;
