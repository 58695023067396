import { useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { components, useApi, ducks } from '@arborian/narrf';
import { Refresh, AddCircleOutline } from '@material-ui/icons';
import { Menu, MenuItem, Chip, IconButton } from '@material-ui/core';
import fp from 'lodash/fp';
import * as R from 'react-router-dom';

import { url_for } from 'og/routes';
import { useUsers } from 'og/datasources/users';
import { useAllRoles } from 'og/datasources/roles';

const { dt2 } = components;

const selectUserOrganization = user =>
    ducks.jsonapi.selectObject(['Organization', fp.get('relationships.organization.data.id', user)]);

const selectUserRoles = user =>
    createSelector(ducks.jsonapi.selectObject(['Role']), roles =>
        fp.map(user_role => roles[user_role.id], fp.get('relationships.roles.data', user)),
    );

function UserOrgLink({ user }) {
    const org = useSelector(selectUserOrganization(user));
    const href = org && url_for('organization', { orgId: org.id });
    const orgName = fp.get('attributes.pcc.name', org);
    return org ? <R.Link to={href}>{orgName}</R.Link> : '--none--';
}

function RoleChip({ user, role, onDeleteRole }) {
    // Read-only superuser role
    if (fp.get('attributes.name', role) === '__admin__') {
        return <Chip label={role.attributes.description} />;
    } else {
        return <Chip label={role.attributes.description} onDelete={ev => onDeleteRole(user, role)} />;
    }
}

function UserRoles({ user, onAddRole, onDeleteRole }) {
    const roles = useSelector(selectUserRoles(user));
    const chips = fp.map(role => <RoleChip user={user} role={role} onDeleteRole={onDeleteRole} />, roles);
    return (
        <>
            {chips}
            <IconButton onClick={ev => onAddRole(ev, user)}>
                <AddCircleOutline />
            </IconButton>
        </>
    );
}

const USER_OPTIONS = { include: ['organization', 'roles'] };

export default function UserDataTable() {
    const api = useApi();
    const ds = useUsers({ options: USER_OPTIONS });
    const [anchorEl, setAnchorEl] = useState();
    const [menuUser, setMenuUser] = useState();
    const roles = useAllRoles();
    const assignableRoles = fp.filter(r => fp.get('attributes.name', r) !== '__admin__', roles);
    const userinfo = useSelector(ducks.auth.selectUserinfo);

    const isAdmin = fp.flow([fp.get('scopes'), fp.indexOf('__admin__')])(userinfo) !== -1;

    const refresh = () => ds.fetch();

    const handleStartAddRole = (ev, user) => {
        setAnchorEl(ev.currentTarget);
        setMenuUser(user);
    };

    const handleAddRole = async role => {
        const user = menuUser;
        const href = fp.get('relationships.roles.links.self', user);
        console.log('Add role', user, role);
        await api.fetchJson(href, {
            method: 'POST',
            json: { data: [{ type: 'Role', id: role.id }] },
        });
        setAnchorEl(null);
        setMenuUser(null);
        await api.fetchJsonApi(user.links.self, USER_OPTIONS);
    };

    const handleDeleteRole = async (user, role) => {
        const href = fp.get('relationships.roles.links.self', user);
        console.log('Delete role', user, role);
        await api.fetchJson(href, {
            method: 'DELETE',
            json: { data: [{ type: 'Role', id: role.id }] },
        });
        await api.fetchJsonApi(user.links.self, USER_OPTIONS);
    };

    return (
        <>
            <dt2.DataTable
                title="User List"
                dataSource={ds}
                size="small"
                options={{
                    filtering: true,
                }}
                editable={{
                    onRowUpdate: ds.handleUpdate,
                }}
            >
                {isAdmin ? (
                    <dt2.Column
                        editable={false}
                        id="organization"
                        title="Organization"
                        render={row => <UserOrgLink user={row.data} />}
                    />
                ) : null}
                <dt2.Column
                    editable={false}
                    id="created"
                    type="date"
                    title="Created"
                    field="attributes.created"
                    filtering={false}
                />
                <dt2.Column editable id="name" title="Name" field="attributes.name" />
                <dt2.Column editable id="email" title="Email" field="attributes.email" />
                <dt2.Column
                    editable={false}
                    id="roles"
                    title="Roles"
                    render={row => (
                        <UserRoles user={row.data} onAddRole={handleStartAddRole} onDeleteRole={handleDeleteRole} />
                    )}
                />
                <dt2.Action
                    editable={false}
                    id="Refresh"
                    name="refresh"
                    free
                    onClick={refresh}
                    tooltip="Refresh"
                    icon={<Refresh />}
                />
            </dt2.DataTable>
            <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                {fp.map(
                    role => (
                        <MenuItem key={role.id} value={role.id} onClick={ev => handleAddRole(role)}>
                            {role.attributes.description}
                        </MenuItem>
                    ),
                    assignableRoles,
                )}
            </Menu>
        </>
    );
}
