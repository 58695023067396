import React from 'react';

import { makeStyles } from '@material-ui/core';

import BasePage from './BasePage';

const useStyles = makeStyles(theme => ({
    parent: {
        textAlign: 'center',
    },
    child: {
        textAlign: 'start',
    },
    logoImg: {
        width: '100%',
        maxWidth: 800,
        margin: theme.spacing(3),
    },
}));

export default function PrivacyPage() {
    const classes = useStyles();

    return (
        <BasePage title="Privacy Policy">
            <div className={classes.parent}>
                <img
                    className={classes.logoImg}
                    alt="PointClickCare + HubSpot CRM Connector"
                    src="/s/logo/svg/logo-no-background.svg"
                />
                <h4>PRIVACY POLICY</h4>
                <p>
                    <i>Last Updated Date: June 1, 2023</i>
                </p>
                <br />
                <p className={classes.child}>
                    PointClickCare + Hubspot CRM Connector (or "we") provides this Privacy Policy to inform you of our
                    policies and procedures regarding the collection, use and disclosure of personal information we
                    receive from users of our website, accessible at <b>https://og.arborian-health.com/</b> ("Site"),
                    and our mobile applications (collectively, "PointClickCare + Hubspot CRM Connector App") as well as
                    use of our proprietary set of services made available through the same ("PointClickCare + Hubspot
                    CRM Connector Services"). This Privacy Policy applies to your access to and use of the Site, the
                    PointClickCare + Hubspot CRM Connector App and the PointClickCare + Hubspot CRM Connector Services,
                    and by choosing to use them, you consent to the collection, transfer, processing, storage,
                    disclosure and other uses of your information described in this Privacy Policy. As used in this
                    policy, the terms "using" and "processing" information include using cookies on a computer,
                    subjecting the information to statistical or other analysis and using or handling information in any
                    way, including, but not limited to collecting, storing, evaluating, modifying, deleting, using,
                    combining, disclosing and transferring information within our organization or among our affiliates
                    within the United States or internationally. As used below, the term "Personal Information" is used
                    to indicate any piece of information or data that can be used to identify any individual. Personal
                    Information may relate to you as a Member, or it may relate to the individual that is the subject of
                    any Profile that you create. Any other defined term that is not defined in this Privacy Policy has
                    the meaning given that term in the PointClickCare + Hubspot CRM Connector Terms of Service or any
                    Additional Terms of Service, as applicable.
                </p>
                <h5>MODIFICATIONS</h5>
                <p className={classes.child}>
                    PointClickCare + Hubspot CRM Connector reserves the right, at its sole discretion, to modify this
                    Privacy Policy, at any time and without prior notice. If we modify this Privacy Policy, we will post
                    the modified Privacy Policy and alert you to the modified Privacy Policy on the Site and the
                    PointClickCare + Hubspot CRM Connector App or will otherwise provide you with notice of the
                    modification. We will also update the "Effective Date" at the top of this Privacy Policy. By
                    continuing to access or use the Site, the PointClickCare + Hubspot CRM Connector App or the
                    PointClickCare + Hubspot CRM Connector Services after we have posted a modified Privacy Policy or
                    have provided you with notice of a modification, you are indicating that you agree to be bound by
                    the modified Privacy Policy. If the modified Privacy Policy is not acceptable to you, your only
                    recourse is to cease using the Site, PointClickCare + Hubspot CRM Connector App and PointClickCare +
                    Hubspot CRM Connector Services. Information we collect under the old Privacy Policy will remain
                    subject to the old Privacy Policy unless and until you consent to the application of any modified
                    Privacy Policy to such information.
                </p>
                <h5>THE INFORMATION WE COLLECT</h5>
                <h5>PERSONAL INFORMATION</h5>
                <p className={classes.child}>
                    You may browse the Site and use the PointClickCare + Hubspot CRM Connector App without expressly
                    providing us any Personal Information that expressly identifies you, however, in order to make full
                    use of the PointClickCare + Hubspot CRM Connector Services as made available via the Site and/or the
                    PointClickCare + Hubspot CRM Connector App, you will be required to register with us. When you
                    register with us to create an account and become a member, we will ask you for certain Personal
                    Information. In order to set up your account, you need to register using your existing
                    PointClickCare login credentials. If you elect to add any other Personal Information to your Account
                    we will collect that information and use it and share it as set forth below. As a Member, you may
                    also set up one or more Profiles within the PointClickCare + Hubspot CRM Connector Services. When
                    you set up a Profile in the PointClickCare + Hubspot CRM Connector Services, we will collect and
                    store any Information that you may elect to provide to us as part of the Profile Content, which may
                    include but is not limited to: medication information, gender, date of birth, personal contacts,
                    photographs and files, notes on your symptoms and other journal information, medical treatment
                    instructions, medical appointments, insurance information, your doctor's address information,
                    advance directives, and prescription refill information. In the event you set up a Profile that
                    relates to someone other than yourself, you are solely responsible for ensuring that you have any
                    and all consents and permissions to share that Personal Information with us and to use that
                    information as part of the PointClickCare + Hubspot CRM Connector Services.
                </p>
                <h5>NON-PERSONAL INFORMATION</h5>
                <p className={classes.child}>
                    We also collect the following information any time you visit our Site or the PointClickCare +
                    Hubspot CRM Connector App, as applicable. This information may not necessarily identify you, but if
                    we have your Personal Information, or you otherwise set up an Account with us, we may link any of
                    the below non-personal information with your Personal Information in our records.
                </p>
                <p className={classes.child}>
                    Browser Log Data. When you visit the Site, whether as a Member or a non-registered user just
                    browsing the Site, our servers automatically record information that your browser sends whenever you
                    visit a website ("Browser Log Data"). This Browser Log Data may include information such as your
                    computer's Internet Protocol ("IP") address, browser type, operating system or the webpage you were
                    visiting before you came to our Site, pages of our Site that you visit, the time spent on those
                    pages, access times and dates, and other statistics. We use this information to monitor and analyze
                    use of the Site and for the Site's technical administration, to increase our Site's functionality
                    and user-friendliness, and to better tailor it to our visitors' needs. For example, we use this
                    information to verify that visitors to the Site meet the criteria required to process their
                    requests. We do not treat Browser Log Data as Personal Information or use it in association with
                    other Personal Information, though we may aggregate, analyze and evaluate such information for the
                    same purposes as stated above regarding other Non-Identifying Information. Cookies. The Site may use
                    "cookies" to collect information. A cookie is a small data file that we transfer to your computer's
                    hard disk for record-keeping purposes. We use cookies for two purposes. First, we utilize persistent
                    cookies to save your login information for future logins to the Site. Second, we utilize session ID
                    cookies to enable certain features of the Site, to better understand how you interact with the Site
                    and to monitor aggregate usage by users and web traffic routing on the Site. Unlike persistent
                    cookies, session cookies are deleted from your computer when you log off from the Site and then
                    close your browser. You can instruct your browser, by changing its options, to stop accepting
                    cookies or to prompt you before accepting a cookie from the websites you visit. If you do not accept
                    cookies, however, you may not be able to use all portions or functionalities of the Site. App Usage
                    Data. When you use the PointClickCare + Hubspot CRM Connector App, whether as a Member or a
                    non-registered user, our servers automatically record information that your mobile device sends
                    whenever you use an Internet service. ("App Usage Data"). This App Usage Data may include
                    information such as your computer's IP address, device model and version, device identifier,
                    operating system, time zone, name of your mobile operator or ISP, application performance
                    measurements, and error conditions. It may also include a record of when specific features of the
                    PointClickCare + Hubspot CRM Connector App or PointClickCare + Hubspot CRM Connector Service are
                    used. We use this information to monitor and analyze use of the PointClickCare + Hubspot CRM
                    Connector App and PointClickCare + Hubspot CRM Connector Service and for their technical
                    administration, to increase their functionality and user-friendliness, and to better tailor them to
                    our visitors' needs. For example, we use this information to measure the popularity of different
                    features and to more effectively resolve customer issues. We may aggregate, analyze and evaluate
                    such information for the same purposes as stated above regarding other Non-Identifying Information.
                    Location Information. The App, with your consent, may access your location. PointClickCare + Hubspot
                    CRM Connector will only access your location services while you are using the App and provided you
                    have opted in to activate this feature. You may choose to allow PointClickCare + Hubspot CRM
                    Connector to access your location by granting the App access to your device's location, when
                    prompted by the App, or through your device's location services settings. PointClickCare + Hubspot
                    CRM Connector uses the Google Places API(s) for location searches and as a result is using the
                    Google Maps/Google Earth API(s). Pursuant to the Google Maps/Google Earth APIs Terms of Service, use
                    of this location feature is also subject to the Google privacy policy, which can be found at
                    http://www.google.com/privacy.html, and is incorporated with this reference.
                </p>
                <h5>HOW WE USE YOUR INFORMATION</h5>
                <p className={classes.child}>
                    We do not sell your Personal Information. We will use any Personal Information in your Account as
                    well as information in your Profile to provide the PointClickCare + Hubspot CRM Connector Services,
                    complete your transactions, administer your inquiries, and present you with offers for products and
                    services that we think may be of use or interest to you. We may contact you in various ways
                    including but not limited to, via email, Inbox in the app, telephone, cell phone, and SMS/text
                    messages. We may aggregate and combine all Personal Information that we receive through your Member
                    Accounts and your Profiles with the information of other Members and Profiles. We use this anonymous
                    information to attempt to provide you with a better experience, to improve the quality and value of
                    our services and to analyze and understand how our Site, PointClickCare + Hubspot CRM Connector App
                    and PointClickCare + Hubspot CRM Connector Services are used.
                </p>
                <h5>INFORMATION SHARING AND DISCLOSURE</h5>
                <p className={classes.child}>
                    <p>
                        Third-parties. You expressly release PointClickCare + Hubspot CRM Connector from any
                        responsibility and liability resulting from any third party access to any Personal Information
                        or Profile Content that you may permit. Any information you choose to provide and make available
                        to third parties via the PointClickCare + Hubspot CRM Connector Services should reflect how much
                        you want others to know about you and the individual(s) whose information is in your Profile.
                        Healthcare Providers. You authorize PointClickCare + Hubspot CRM Connector to request and
                        receive protected health information (PHI) from healthcare providers you may have seen in the
                        past to assist us in organizing and presenting your information to you. If you elect to use or
                        otherwise take advantage of some of the other third party services that we may provide and make
                        available to you, we will share certain information with such third party and will disclose to
                        you at the time what information would be necessary to share with such third party.
                    </p>
                    <p>
                        Aggregate Information and Non-Identifying Information. We may share aggregated information that
                        does not include Personal Information and we may otherwise disclose non-Personal Information
                        with third parties for industry analysis, demographic profiling and other purposes.
                    </p>
                    <p>
                        Service Providers. We will disclose Personal Information to the third party service providers
                        that we engage to assist us in making the Site, PointClickCare + Hubspot CRM Connector App and
                        PointClickCare + Hubspot CRM Connector Services available. These service providers consist of
                        our datacenter provider, storage provider, customer support system provider, email service
                        providers, and software development contractors. These third parties have access to your
                        Personal Information only to perform these tasks on our behalf and are obligated not to disclose
                        or use it for any other purpose. Compliance with Laws and Law Enforcement. While PointClickCare
                        + Hubspot CRM Connector maintains information regarding your health on your behalf,
                        PointClickCare + Hubspot CRM Connector is not a covered entity under the Health Insurance
                        Portability and Accountability Act (HIPAA). However, we take the protection of your private
                        health information very seriously. In the event that we receive any subpoena or similar request
                        from a court, government or law enforcement official, we will only disclose any Personal
                        Information about you or other information in our custody or control as we, in our sole
                        discretion, believe necessary or appropriate to respond to claims and legal process (including
                        but not limited to subpoenas), to protect the property and rights of PointClickCare + Hubspot
                        CRM Connector or a third party, to protect the safety of the public or any person, or to prevent
                        or stop activity we may consider to be, or to pose a risk of being, illegal, unethical or
                        legally actionable activity. Given the sensitivity of the Personal Information within your
                        Profiles, it is our policy to carefully review any such request for information in our systems
                        and we will decline to comply with any such request in our discretion.
                    </p>
                    <p>
                        Business Transfers. PointClickCare + Hubspot CRM Connector may sell, transfer or otherwise share
                        some or all of its assets, including your Personal Information and related Account, Profiles and
                        Profile Content, in connection with a merger, acquisition, reorganization or sale of assets or
                        in the event of bankruptcy.
                    </p>
                </p>
                <h5>CHANGING OR DELETING YOUR INFORMATION</h5>
                <p className={classes.child}>
                    All Members may review, update, correct or delete their Account information and any associated
                    Personal Information or Profile Content by contacting us or by accessing the PointClickCare +
                    Hubspot CRM Connector Services. If you would like to close your Account or delete any Profile from
                    your Account, please contact us at services@arborian-health.com. We will use commercially reasonable
                    efforts to honor your request. We will retain an archived copy of your records as set forth below.
                </p>
                <h5>DATA RETENTION</h5>
                <p className={classes.child}>
                    We will retain the information you provide via our Site or the PointClickCare + Hubspot CRM
                    Connector App for as long as your Account is active or as needed to provide you with the
                    PointClickCare + Hubspot CRM Connector Services. We will also retain and use the information you
                    provide via our Site or PointClickCare + Hubspot CRM Connector App, as necessary to comply with our
                    legal obligations, resolve disputes, and enforce our agreements. If you delete your Account or any
                    Profile, any Personal Information associated with the same is moved out of our active databases, but
                    will still be retained in our systems pursuant to our data backup and retention policies.
                </p>
                <h5>DO NOT TRACK SIGNALS AND AD NETWORKS</h5>
                <p className={classes.child}>
                    Our Site does not have the capability to respond to "Do Not Track" signals received from various web
                    browsers.
                </p>
                <h5>SECURITY - HOW WE PROTECT YOUR DATA</h5>
                <p className={classes.child}>
                    All communications between you and the Site, and between the App and the Site, are encrypted using
                    the highest level encryption supported by your device. Data stored in the app and on the Site is
                    encrypted at rest using industry-standard AES encryption.
                </p>
                <h5>IDENTITY THEFT</h5>
                <p className={classes.child}>
                    Identity theft is of great concern to PointClickCare + Hubspot CRM Connector. Safeguarding
                    information to help protect you from identity theft is a top priority. We do not and will not, at
                    any time, request your credit card information, your login information, or national identification
                    numbers in a non-secure or unsolicited e-mail or telephone communication. For more information about
                    identity theft, visit the Federal Trade Commission's website at www.ftc.gov.
                </p>
                <h5>SECURITY BREACH</h5>
                <p className={classes.child}>
                    The Site, PointClickCare + Hubspot CRM Connector App and PointClickCare + Hubspot CRM Connector
                    Services rely on the Internet and mobile networks, which are not always secure. We use commercially
                    reasonable efforts to maintain the confidentiality, availability and integrity of any information we
                    collect, but we cannot assure that the use of the Site, PointClickCare + Hubspot CRM Connector App
                    or PointClickCare + Hubspot CRM Connector Services are secure or that your Personal Information or
                    any other data that we may have will be secure. In the event we become aware of a security incident,
                    we will notify you to the extent required under applicable law.
                </p>
                <h5>REPORTING A PROBLEM</h5>
                <p className={classes.child}>
                    If you feel your account may have been compromised, or you discover abuse or misuse of
                    PointClickCare + Hubspot CRM Connector Services, the Site or the PointClickCare + Hubspot CRM
                    Connector App, please report it immediately to services@arborian-health.com and we'll investigate.
                </p>
                <p className={classes.child}>
                    If you're a security researcher who's discovered an issue, please report it immediately to
                    services@arborian-health.com and we'll investigate.
                </p>
                <h5>INTERNATIONAL TRANSFER</h5>
                <p className={classes.child}>
                    Your information may be transferred to — and maintained on — computers located outside of your
                    state, province, country or other governmental jurisdiction where the privacy laws may not be as
                    protective as those in your jurisdiction. If you are located outside the United States and choose to
                    provide information to us, PointClickCare + Hubspot CRM Connector transfers Personal Information to
                    the United States and processes it there. Your consent to this Privacy Policy followed by your
                    submission of such information represents your agreement to that transfer.
                </p>
                <h5>LINKS TO OTHER WEBSITES</h5>
                <p className={classes.child}>
                    Our Site may contain links to other websites. If you choose to click on a third party link, you will
                    be directed to that third party's website. The fact that we link to a website is not an endorsement,
                    authorization or representation of our affiliation with that third party, nor is it an endorsement
                    of their privacy or information security policies or practices. We do not exercise control over
                    third party websites. These other websites may place their own cookies or other files on your
                    computer, collect data or solicit personal information from you. Other websites follow different
                    rules regarding the use or disclosure of the personal information you submit to them. We encourage
                    you to read the privacy policies or statements of the other websites you visit.
                </p>
                <h5>OUR POLICY TOWARD CHILDREN</h5>
                <p className={classes.child}>
                    The Site and PointClickCare + Hubspot CRM Connector Apps are not directed to persons under 13. We do
                    not knowingly collect personally identifiable information from children under 13. If a parent or
                    guardian becomes aware that his or her child has provided us with Personal Information without their
                    consent, he or she should contact us at services@arborian-health.com. If we become aware that a
                    child under 13 has provided us with Personal Information, we will delete such information from our
                    files. If you have created a Profile for a minor under the age of 18, you represent and warrant to
                    us at PointClickCare + Hubspot CRM Connector that you are the parent or legal guardian for that
                    minor with a right to provide us the Personal Information of that minor.
                </p>
                <h5>CONTACTING POINTCLICKCARE + HUBSPOT CRM CONNECTOR</h5>
                <p className={classes.child}>
                    If you have any questions about this Privacy Policy, please contact PointClickCare + Hubspot CRM
                    Connector at: services@arborian-health.com
                </p>
                <br />
            </div>
        </BasePage>
    );
}
