import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import fp from 'lodash/fp';

import { useApi, ducks, components } from '@arborian/narrf';

const { useQueryFetchOptions, useDataActions, useReduxDataSource } = components;

const defaultFetchOptions = {
    page: { number: 0, size: 20 },
};

const defaultOptions = {};
const defaultMergeFetchOptions = {};

export default function useResources({
    href = null,
    prefix = null, // used in query string params
    link = null, // api.directory link (if href is null)
    type = null, // used in creating new items
    options = defaultOptions,
    initialFetchOptions = defaultFetchOptions,
    mergeFetchOptions = defaultMergeFetchOptions,
}) {
    const api = useApi();
    const history = useHistory();
    const dispatch = useDispatch();
    if (!href) {
        href = api.hasDirectory && link && api.url_for(link);
    }
    const [fetchOptions, setFetchOptions] = useQueryFetchOptions(history, prefix, initialFetchOptions);

    const fetchItems = useCallback(
        async _fetchOptions => {
            if (!href)
                return {
                    rows: [],
                };
            await api.bootstrap();
            if (!fp.isEmpty(mergeFetchOptions)) {
                console.log('Got mFO', mergeFetchOptions);
                console.log('merged', fp.merge(mergeFetchOptions, _fetchOptions));
            }
            const result = await api.fetchDataTable(href, fp.merge(mergeFetchOptions, _fetchOptions), options);
            return result;
        },
        [api, href, options, mergeFetchOptions],
    );
    const ds = useReduxDataSource({ data: fetchItems, fetchOptions });
    const actions = useDataActions(fetchOptions, setFetchOptions);

    const handleAdd = useCallback(
        async item => {
            const resp = await api.fetchJson(href, {
                method: 'POST',
                json: { data: { type, ...item.data } },
            });
            await ds.fetch();
            return resp;
        },
        [api, type, href, ds],
    );

    const handleUpdate = useCallback(
        async item => {
            await api.fetchJson(item.data.links.self, {
                method: 'PATCH',
                json: { data: item.data },
            });
            await ds.fetch();
        },
        [api, ds],
    );

    const handleDelete = useCallback(
        async item => {
            await api.fetch(item.data.links.self, { method: 'DELETE' });
            dispatch(ducks.jsonapi.deleteData(item.data));
            await ds.fetch();
        },
        [api, dispatch, ds],
    );

    return {
        ...ds,
        ...actions,
        handleAdd,
        handleUpdate,
        handleDelete,
    };
}
