import useResources from './resources';
import { useEffect } from 'react';
import { ducks, useApi } from '@arborian/narrf';
import { useSelector } from 'react-redux';

const INITIAL_FETCH_OPTIONS = {
    filter: {},
    page: { number: 0, size: 20 },
    sort: { field: 'attributes.created', direction: 'desc' },
};

export function useUser(userId) {
    const api = useApi();

    useEffect(() => {
        api.fetchJson(api.url_for('user.User', { user_id: userId }));
    }, [api, userId]);

    return useSelector(ducks.jsonapi.selectObject(['User', userId]));
}

export function useUsers(props) {
    return useResources({
        link: 'user.UserCollection',
        prefix: 'user.',
        initialFetchOptions: INITIAL_FETCH_OPTIONS,
        ...props,
    });
}
