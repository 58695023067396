import React from 'react';
import { useSelector } from 'react-redux';
import fp from 'lodash/fp';
import * as R from 'react-router-dom';

import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import * as I from '@material-ui/icons';

import { url_for } from 'og/routes';
import { useApi, ducks } from '@arborian/narrf';
import { selectUserOrganization } from 'og/selectors';

function AuthorizedEntries({ org }) {
    return (
        <>
            {org ? (
                <ListItem button component={R.Link} to={url_for('organization', { orgId: org.id })}>
                    <ListItemIcon>
                        <I.Business />
                    </ListItemIcon>
                    <ListItemText primary="Facilities" />
                </ListItem>
            ) : null}
            <ListItem button component={R.Link} to={url_for('patients')}>
                <ListItemIcon>
                    <I.PersonOutline />
                </ListItemIcon>
                <ListItemText primary="Patients" />
            </ListItem>
            <ListItem button component={R.Link} to={url_for('users')}>
                <ListItemIcon>
                    <I.SupervisorAccount />
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItem>
            <ListItem button component={R.Link} to={url_for('contacts')}>
                <ListItemIcon>
                    <I.Contacts />
                </ListItemIcon>
                <ListItemText primary="HubSpot Contacts" />
            </ListItem>
            <ListItem button component={R.Link} to={url_for('patients_contacts')}>
                <ListItemIcon>
                    <I.ContactPhoneRounded />
                </ListItemIcon>
                <ListItemText primary="Patients Contacts" />
            </ListItem>
        </>
    );
}

function AdminEntries() {
    return (
        <>
            <ListItem button component={R.Link} to={url_for('organizations')}>
                <ListItemIcon>
                    <I.Build />
                </ListItemIcon>
                <ListItemText primary="Active Organizations" />
            </ListItem>
        </>
    );
}

export default function NavDrawer({ open, onClose }) {
    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);

    const isAdmin = fp.flow([fp.get('scopes'), fp.indexOf('__admin__')])(userinfo) !== -1;
    const org = useSelector(selectUserOrganization);

    return (
        <Drawer open={open} onClose={onClose}>
            <List>
                <ListItem button component={R.Link} to={url_for('home')}>
                    <ListItemIcon>
                        <I.Home />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                {api.isAuthorized() && <AuthorizedEntries org={org} />}
                {isAdmin && <AdminEntries org={org} />}
            </List>
        </Drawer>
    );
}
