import { components, useApi } from '@arborian/narrf';
import { Refresh } from '@material-ui/icons';
import { usePatientsContacts } from 'og/datasources/patients-contacts';
import { useOrganizationMap } from 'og/datasources/organizations';
import fp from 'lodash/fp';
import { useSnackbar } from 'notistack';
import {
    DOBColumn,
    EmailColumn,
    FacilityColumn,
    FirstNameColumn,
    GenderColumn,
    LastNameColumn,
    PhoneColumn,
} from 'og/components/columns/PatientDataTableColumns';
import { commonActions, commonCapabilities } from 'og/components/actions/actions';
import { hsIsLinked, UpdateHS } from 'og/components/actions/StatusActions';
import { usePatientStyles } from 'og/styles';
import { UpdateHSAction } from 'og/components/actions/TableActions';

const { dt2 } = components;

const PatientContactActions = ({ row, actions, orgs }) => {
    const classes = usePatientStyles();
    const { canUpdateHS } = commonCapabilities(row.data);

    return (
        <div className={classes.statusActions}>
            <UpdateHS
                className={classes.hubspotBg}
                row={row}
                action={actions.updateHubspot}
                hsIsLinked={hsIsLinked([row], orgs)}
                canUpdateHS={canUpdateHS}
            />
        </div>
    );
};

export default function PatientContactDataTable() {
    const classes = usePatientStyles();

    const api = useApi();

    const ds = usePatientsContacts();

    const organizations = useOrganizationMap();

    const refresh = () => ds.fetch();
    const { enqueueSnackbar } = useSnackbar();
    const actions = commonActions(api, ds, enqueueSnackbar);

    return (
        <div className={classes.root}>
            <dt2.DataTable
                title="Patient Contact List"
                dataSource={ds}
                size="small"
                options={{
                    selection: true,
                    filtering: true,
                }}
            >
                <dt2.Column
                    id="patient"
                    title="Related Patient"
                    field="attributes.patient_name"
                    render={row => {
                        const contact = fp.get('attributes', row.data);
                        return contact.patient_name;
                    }}
                />

                <FacilityColumn />
                <LastNameColumn />
                <FirstNameColumn />
                <EmailColumn />
                <PhoneColumn />
                <DOBColumn />
                <GenderColumn />

                <dt2.Column
                    id="status_actions"
                    filtering={false}
                    render={row => <PatientContactActions row={row} actions={actions} orgs={organizations} />}
                />

                <UpdateHSAction orgs={organizations} action={actions.updateHubspot} />

                <dt2.Action id="refresh" name="refresh" free onClick={refresh} tooltip="Refresh" icon={<Refresh />} />
            </dt2.DataTable>
        </div>
    );
}
