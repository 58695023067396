import React from 'react';
import fp from 'lodash/fp';

import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

import { components } from '@arborian/narrf';

import useResources from 'og/datasources/resources';

const { dt2 } = components;

const useStyles = makeStyles(theme => ({
    hidden: {
        display: 'none',
    },
}));

export function useRelated(resource, rel, props) {
    return useResources({
        prefix: `res-${rel}.`,
        href: fp.get(['relationships', rel, 'links', 'related'], resource),
        ...props,
    });
}

export default function PatientDetailTable({ patient }) {
    const classes = useStyles();
    const ds = useRelated(patient, 'patient_contacts', 'pt-contacts.');

    return (
        <>
            {ds.state === 'ready' && fp.isEmpty(ds.items) ? (
                <Alert severity="info">There are no contacts mapped to this patient</Alert>
            ) : null}
            {ds.state === 'busy' ? <Alert severity="success">Fetching data...</Alert> : null}
            <div className={fp.isEmpty(ds.items) ? classes.hidden : null}>
                <dt2.DataTable
                    title="Patient's contacts"
                    dataSource={ds}
                    options={{
                        search: false,
                        filtering: false,
                        paging: false,
                    }}
                >
                    <dt2.Column title="First" field="attributes.first_name" />
                    <dt2.Column title="Last" field="attributes.last_name" />
                    <dt2.Column
                        title="DOB"
                        field="attributes.dob"
                        type="date"
                        render={row => fp.get('data.attributes.dob', row)}
                    />
                    <dt2.Column title="Email" field="attributes.email" />
                    <dt2.Column title="Phone" field="attributes.phone" />
                    <dt2.Column title="Gender" field="attributes.pcc.gender" />
                    <dt2.Column title="Relationship" field="attributes.pcc.relationship" />
                </dt2.DataTable>
            </div>
        </>
    );
}
