import { components, useApi, useDialog } from '@arborian/narrf';
import { Refresh } from '@material-ui/icons';
import { usePatients } from 'og/datasources/patients';
import PatientDetailTable from './PatientDetailTable';
import { commonActions, commonCapabilities } from 'og/components/actions/actions';
import { hsIsLinked, UpdateHS, Split, UpdatePCC, UploadFile } from 'og/components/actions/StatusActions';
import { usePatientStyles } from 'og/styles';

import {
    FacilityColumn,
    PatientStatusColumn,
    LastNameColumn,
    FirstNameColumn,
    EmailColumn,
    PhoneColumn,
    DOBColumn,
    GenderColumn,
} from 'og/components/columns/PatientDataTableColumns';
import { useOrganizationMap } from 'og/datasources/organizations';
import { useSnackbar } from 'notistack';
import {
    UpdateHSAction,
    SplitAction,
    MergeAction,
    UpdatePCCAction,
    UploadFileAction,
} from 'og/components/actions/TableActions';

const { dt2 } = components;

const PatientActions = ({ row, actions, orgs }) => {
    const classes = usePatientStyles();
    const { canUpdateHS, canSplit, canUpdatePCC, hasPCCPatient, hasHSContact } = commonCapabilities(row.data);
    return (
        <div className={classes.statusActions}>
            <UpdateHS
                className={classes.hubspotBg}
                row={row}
                action={actions.updateHubspot}
                hsIsLinked={hsIsLinked([row], orgs)}
                canUpdateHS={canUpdateHS}
            />
            <Split
                className={classes.splitBg}
                row={row}
                action={actions.splitRecords}
                hsIsLinked={hsIsLinked([row], orgs)}
                canSplit={canSplit}
            />
            <UpdatePCC
                className={classes.pccBg}
                row={row}
                action={actions.updatePCC}
                hsIsLinked={hsIsLinked([row], orgs)}
                canUpdatePCC={canUpdatePCC}
            />
            <UploadFile
                className={classes.uploadBg}
                row={row}
                action={actions.uploadFile}
                hsIsLinked={hsIsLinked([row], orgs)}
                hasPCCPatient={hasPCCPatient}
                hasHSContact={hasHSContact}
            />
        </div>
    );
};

export default function PatientDataTable() {
    const classes = usePatientStyles();
    const api = useApi();
    const ds = usePatients();

    const organizations = useOrganizationMap();
    const { enqueueSnackbar } = useSnackbar();
    const uploadDialog = useDialog('upload');
    const actions = commonActions(api, ds, uploadDialog, enqueueSnackbar);

    const refresh = () => ds.fetch();

    return (
        <div className={classes.root}>
            <dt2.DataTable
                title="Patient List"
                dataSource={ds}
                size="small"
                options={{
                    selection: true,
                    filtering: true,
                }}
            >
                <dt2.DetailPanel
                    key="map-patients-contacts-detail"
                    tooltip="Mapped Contacts"
                    render={row => <PatientDetailTable patient={row.data} handleRefresh={refresh} />}
                />

                <FacilityColumn />
                <PatientStatusColumn />
                <LastNameColumn />
                <FirstNameColumn />
                <EmailColumn />
                <PhoneColumn />
                <DOBColumn />
                <GenderColumn />

                <dt2.Column
                    id="status_actions"
                    filtering={false}
                    render={row => <PatientActions row={row} actions={actions} orgs={organizations} />}
                />

                <UpdateHSAction orgs={organizations} action={actions.updateHubspot} />

                <SplitAction orgs={organizations} action={actions.splitRecords} />

                <MergeAction orgs={organizations} action={actions.mergeRecords} />

                <UpdatePCCAction orgs={organizations} action={actions.updatePCC} />

                <UploadFileAction orgs={organizations} action={actions.uploadFile} />
                <dt2.Action id="refresh" name="refresh" free onClick={refresh} tooltip="Refresh" icon={<Refresh />} />
            </dt2.DataTable>
        </div>
    );
}
