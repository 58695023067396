import React from 'react';

import BasePage from 'og/pages/BasePage';
import PatientContactDataTable from 'og/components/datatables/PatientContactDataTable';

export default function PatientContactListPage() {
    return (
        <BasePage title="Patients' Contacts">
            <PatientContactDataTable />
        </BasePage>
    );
}
