import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Button, IconButton, Tooltip } from '@material-ui/core';
import * as I from '@material-ui/icons';

import { ducks } from '@arborian/narrf';

import { url_for } from 'og/routes';

function LogoutButton() {
    const history = useHistory();

    const logout = useCallback(async () => {
        history.push(url_for('logout'));
    }, [history]);

    return (
        <Tooltip title="Log out">
            <IconButton onClick={logout} color="inherit">
                <I.PowerSettingsNew />
            </IconButton>
        </Tooltip>
    );
}

function AuthInfoAuthorized({ userinfo }) {
    return (
        <span>
            Logged in as {userinfo.name}
            <LogoutButton />
        </span>
    );
}

function AuthInfoAnonymous() {
    return (
        <>
            <Button color="inherit" component={Link} to={url_for('login')}>
                Login
            </Button>
            <Button color="inherit" component={Link} to={url_for('register')}>
                Register
            </Button>
        </>
    );
}

export default function AuthInfo() {
    const userinfo = useSelector(ducks.auth.selectUserinfo);

    return userinfo ? <AuthInfoAuthorized userinfo={userinfo} /> : <AuthInfoAnonymous />;
}
