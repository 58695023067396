import { useApi } from '@arborian/narrf';

export function useHubspotAccountLink() {
    const api = useApi();

    const linkHubspot = async (ev, org) => {
        const url = api.url_for('organization.OrganizationHubspotLink', { org_id: org.id });
        const href = `${url}?return_url=${window.location.href}`;
        window.location = href;
    };

    return linkHubspot;
}
