import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { useApi, util } from '@arborian/narrf';

import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { url_for } from 'og/routes';

import BasePage from './BasePage';

function RegistrationHelper() {
    return (
        <Alert severity="warning">
            <h4>User not found</h4>
            <p>Your credentials appeared valid, but your account on this server was not found.</p>
            <p>Please click the below button to register your account here and sign in.</p>
            <Button to={url_for('register')} variant="contained" color="primary" component={Link}>
                Register
            </Button>
        </Alert>
    );
}

export default function AuthorizationCode() {
    const api = useApi();
    const query = util.useQuery();
    const history = useHistory();
    const [init, setInit] = useState(false);
    const [message, setMessage] = useState('Authorizing...');
    const [needsRegistration, setNeedsRegistration] = useState(false);

    util.useAsyncEffect(async () => {
        if (init) return;
        setInit(true);
        const code = query.get('code');
        const intent = query.get('intent');
        const error = query.get('error');
        if (error === 'User not found') {
            setMessage(error);
            setNeedsRegistration(true);
        }
        if (error) {
            setMessage(`Auth error: ${error}`);
            return;
        }

        if (intent === 'link') {
            let refreshed = await api.tryRefresh();
            if (refreshed) {
                await api.codeLink(code);
                history.push(url_for('home'));
            } else {
                console.error('Failed to refresh');
                history.push(url_for('home'));
            }
        } else {
            let tok = await api.codeLogin(code);
            console.log('Got token response', tok);
            history.push(url_for('home'));
        }
    }, [init]);

    return <BasePage subtitle="Callback">{needsRegistration ? <RegistrationHelper /> : <div>{message}</div>}</BasePage>;
}
