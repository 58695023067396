import React from 'react';
import { useParams } from 'react-router-dom';
import fp from 'lodash/fp';

import BasePage from 'og/pages/BasePage';
import { OrganizationDetailView } from 'og/components/views/Organization/OrganizationDetailView';
import { useOrganization } from '../datasources/organizations';

export default function OrganizationListPage() {
    const { orgId } = useParams();

    const org = useOrganization(orgId);

    return (
        <BasePage title="Organization">
            <h4>{fp.get('attributes.pcc.name', org)}</h4>
            <OrganizationDetailView organization={org} />
        </BasePage>
    );
}
