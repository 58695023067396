import { useApi } from '@arborian/narrf';
import { useOrganizations } from 'og/datasources/organizations';

export function useHubspotAccountUnlink() {
    const api = useApi();

    const ds = useOrganizations();

    const refresh = () => ds.fetch();

    const unlinkHubspot = async (event, org) => {
        const url = api.url_for('organization.OrganizationHubspotLink', {
            org_id: org.id,
        });

        await api.fetchJson(url, {
            method: 'DELETE',
        });

        refresh();
    };

    return unlinkHubspot;
}
