import React from 'react';

import BasePage from 'og/pages/BasePage';
import PatientDataTable from 'og/components/datatables/PatientDataTable';

export default function PatientListPage() {
    return (
        <BasePage title="Patients">
            <PatientDataTable />
        </BasePage>
    );
}
